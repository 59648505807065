import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "react-vertical-timeline-component/style.min.css";
import "../styles/teams.css";
import Reveal from "../../utils/motion/reveal";

import {
  teamImage,
  Ciprian,
  defaultPic,
  Ligia,
  Magda,
  nustiu1,
  nustiu2,
  ronela,
  teo,
  sabine,
} from "../../assets";
import Footer from "../Footer/Footer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const Teams = (props) => {
  const { t } = props;

  return (
    <div>
      <div className="page-container">
        <div>
          <div
            className="page-default-banner"
            style={{
              backgroundImage: `url(${teamImage})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              padding: "145px 0",
            }}
          >
            <div className="row">
              <h1>
                <Reveal>
                  <span className="color-big-heading">{t("Menu.Team")}</span>
                </Reveal>
              </h1>
              <Reveal>
                <ul className="breadcrumb-wrapper">
                  <li className="breadcrumb-item">{t("Menu.Team")}</li>
                  <li className="breadcrumb-item">
                    <ArrowForwardIcon></ArrowForwardIcon>
                  </li>
                  <li className="breadcrumb-item ">{t("Menu.Team")}</li>
                </ul>
              </Reveal>
            </div>
          </div>
        </div>

        <section className="section-container">
          <div className="section-row">
            <div className="section-col1">
              <div className="section-text">
                <Reveal>
                  <div className="section-text-title">
                    <h1>{t("Team.TeamTitle")}</h1>
                  </div>
                </Reveal>

                <Reveal>
                  <div className="section-text-p">
                    <p>{t("Team.TeamDesc1")}</p>

                    <p>{t("Team.TeamDesc2")}</p>
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </section>

        <Reveal>
          <div className="teams-title">
            <h2>
              {t("Team.MeetThe")}{" "}
              <span style={{ color: "#d68231" }}>{t("Team.team")}.</span>
            </h2>
          </div>
        </Reveal>

        <div className="team-members-container">
          <Reveal>
            <div className="team-row">
              <div className="member">
                <div className="square">
                  <img className="thumb" src={Ligia} />
                </div>
                <div className="member-info">
                  <h4>Ligia & Janne</h4>
                  <p>Ligia- {t("Team.Ligia")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <img className="thumb" src={defaultPic} />
                </div>
                <div className="member-info">
                  <h4>Dionis Bodiu</h4>
                  <p>{t("Team.Dionis")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <img className="thumb" src={defaultPic} />
                </div>
                <div className="member-info">
                  <h4>Tabita Koteles</h4>
                  <p>{t("Team.Tabita")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <img className="thumb" src={ronela} />
                </div>
                <div className="member-info">
                  <h4>Ronela Bodiu</h4>
                  <p>{t("Team.Ronela")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <img className="thumb" src={nustiu1} />
                </div>
                <div className="member-info">
                  <h4>Laura Cosercar</h4>
                  <p>{t("Team.Laura")}</p>
                </div>
              </div>
            </div>
          </Reveal>

          <Reveal>
            <div className="team-row">
              <div className="member">
                <div className="square">
                  <div className="inner">
                    <img className="thumb" src={teo} />
                  </div>
                </div>
                <div className="member-info">
                  <h4>Teodor Bodiu</h4>
                  <p>{t("Team.Teo")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <div className="inner">
                    <img className="thumb" src={Ciprian} />
                  </div>
                </div>
                <div className="member-info">
                  <h4>Ciprian-Raul Crisan</h4>
                  <p>{t("Team.Ciprian")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <div className="inner">
                    <img className="thumb" src={Magda} />
                  </div>
                </div>
                <div className="member-info">
                  <h4>Magda Corsercar</h4>
                  <p>{t("Team.Magda")}</p>
                </div>
              </div>

              <div className="member">
                <div className="square">
                  <div className="inner">
                    <img className="thumb" src={sabine} />
                  </div>
                </div>
                <div className="member-info">
                  <h4>Sabine Zimmermann</h4>
                  <p>{t("Team.Sabine")}</p>
                </div>
              </div>
            </div>
          </Reveal>
        </div>
      </div>
      <Footer />
    </div>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Teams);
