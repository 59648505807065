import React, { useEffect, useState } from 'react'
import NewsCard from './NewsCard'
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import '../styles/newsfeed.css';
import Reveal from '../../utils/motion/reveal';
import { Button, Dialog } from '@mui/material';

import { backgroundImage, rondola, rondola2, rondola3, rondola4, rondola5 } from '../../assets';
import Dialog1 from './dialogs/Dialog1';
import Dialog2 from './dialogs/Dialog2';
import Dialog3 from './dialogs/Dialog3';
import Dialog4 from './dialogs/Dialog4';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Link } from 'react-router-dom'
import { house1 } from '../../assets/houseOfJoy';
import { banner } from '../../assets/news-images/warm feet 2024';



const NewsFeed = (props) => {
    const { t, i18n } = props;

    const [languageValue, setLanguageValue] = useState("en");

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 3000,
        arrows: true,
    };

    const newsPostsEn = [
        { title: "Warm Feet 2024", image: banner, description: "Imagine a child's face lighting up as they receive a warm pair of boots.", linkDestination: "/news/Warm-Feet-2024" },
        { title: "Albesti summer camp", image: house1, description: "Families in unity and diversity!", linkDestination: "/news/Albesti-summer-camp" },
        { title: "Spread your wings and fly", image: rondola5, description: "Summer Camp with Goldberich and Hoghilag children and teens", linkDestination: "/news/Spread-your-wings-and-fly" },
        { title: "Spring update 2024", image: rondola4, description: "A big thank you to all of you who have been giving towards the work in Sighisoara in different ways during 2024.", linkDestination: "/news/Spring-update" },
        // { title: "Food Bank Donations", image: "https://via.placeholder.com/300x200/33A1FF/FFFFFF?text=News+5", description: "Providing meals to families in need.", linkDestination: "/news/Food-Bank-Donations" },
    ];

    const newsPostsRo = [
        { title: "Picioare calde 2024", image: banner, description: "Imaginează-ți chipul unui copil luminându-se de bucurie când primește o pereche de cizme călduroase.", linkDestination: "/news/Warm-Feet-2024" },
        { title: "Tabăra de vară Albești", image: house1, description: "Familii în unitate și diversitate!", linkDestination: "/news/Albesti-summer-camp" },
        { title: "Întinde-ți aripile și zboară", image: rondola5, description: "Tabără de vară cu copiii și adolescenții din Goldberich și Hoghilag", linkDestination: "/news/Spread-your-wings-and-fly" },
        { title: "Actualizare de primăvară 2024", image: rondola4, description: "Un mare mulțumesc tuturor celor care au contribuit în diferite moduri la activitățile din Sighișoara în 2024.", linkDestination: "/news/Spring-update" },
        // { title: "Donații pentru Banca de Alimente", image: "https://via.placeholder.com/300x200/33A1FF/FFFFFF?text=News+5", description: "Oferim mese familiilor aflate în nevoie.", linkDestination: "/news/Food-Bank-Donations" },
    ];


    const newsPostsSw = [
        { title: "Varma Fötter 2024", image: banner, description: "Föreställ dig ett barns ansikte lysa upp när de får ett par varma stövlar.", linkDestination: "/news/Warm-Feet-2024" },
        { title: "Albesti sommarläger", image: house1, description: "Familjer i enhet och mångfald!", linkDestination: "/news/Albesti-summer-camp" },
        { title: "Spänn ut dina vingar och flyg", image: rondola5, description: "Sommarläger med barn och tonåringar från Goldberich och Hoghilag", linkDestination: "/news/Spread-your-wings-and-fly" },
        { title: "Våruppdatering 2024", image: rondola4, description: "Ett stort tack till er alla som har bidragit till arbetet i Sighișoara på olika sätt under 2024.", linkDestination: "/news/Spring-update" },
        // { title: "Matbanksdonationer", image: "https://via.placeholder.com/300x200/33A1FF/FFFFFF?text=News+5", description: "Vi tillhandahåller måltider till familjer i behov.", linkDestination: "/news/Food-Bank-Donations" },
    ];

    useEffect(() => {
        setLanguageValue(i18n.language);
    }, [i18n.language]);

    return (
        <Reveal>
            <div className='news-container' style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="news-container-content">
                    <Reveal>
                        <div className="news-title">
                            <h2>
                                {t("NewsFeed.NewsFeedTitle")}
                            </h2>
                        </div>
                    </Reveal>

                    <div className="news-slider-container">

                        {languageValue == "en" && (
                            <Slider {...settings}>
                                {newsPostsEn.map((post, index) => (
                                    <div key={index} className="news-slider-item">
                                        <NewsCard
                                            title={post.title}
                                            image={post.image}
                                            description={post.description}
                                            linkDestination={post.linkDestination}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        )}

                        {languageValue == "ro" && (
                            <Slider {...settings}>
                                {newsPostsRo.map((post, index) => (
                                    <div key={index} className="news-slider-item">
                                        <NewsCard
                                            title={post.title}
                                            image={post.image}
                                            description={post.description}
                                            linkDestination={post.linkDestination}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        )}

                        {languageValue == "se" && (
                            <Slider {...settings}>
                                {newsPostsSw.map((post, index) => (
                                    <div key={index} className="news-slider-item">
                                        <NewsCard
                                            title={post.title}
                                            image={post.image}
                                            description={post.description}
                                            linkDestination={post.linkDestination}
                                        />
                                    </div>
                                ))}
                            </Slider>
                        )}



                    </div>

                    <div className="news-button">
                        <Link to={"/news"}>
                            <Button variant="contained" size="large" className="news-button-style">{t("Buttons.AllNewsButton")}</Button>
                        </Link>
                    </div>

                </div>
            </div>
        </Reveal>

    )
}

const enhance = compose(withTranslation("translation"));

export default enhance(NewsFeed);