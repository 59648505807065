import React, { useState, useEffect } from "react";
import { banner,warm4, warm1, warm10, warm11, warm12, warm13, warm2, warm3, warm5, warm6, warm7, warm8, warm9 } from "../../../assets/news-images/warm feet 2024";
import { withTranslation } from "react-i18next";
import { rondola, rondola4, rondola5 } from "../../../assets";
import Footer from "../../Footer/Footer";
import { compose } from 'redux';
import ImageSlider from "../../../utils/image-slider/image-slider";


const NewsPage5 = (props) => {
    const { t, i18n } = props;

    const [languageValue, setLanguageValue] = useState("en");

    const images = [warm1,warm2,warm3,warm4,warm5];
    const images2 = [warm6,warm7,warm8,warm9,warm10];
    const images3 = [warm11,warm12,warm13];

    useEffect(() => {
        setLanguageValue(i18n.language);
    }, [i18n.language]);

    return (
        <div>
            <div className="page-container">
                <div
                    className="page-default-banner"
                    style={{
                        backgroundImage: `url(${banner})`,
                        backgroundSize: "cover",
                        backgroundPosition: "50%",
                        padding: "145px 0",
                    }}
                >
                    <div className="article">
                        <div className="row-article">
                            <h1>
                                {languageValue == "en" && (
                                    <span className="article-title">Warm Feet 2024</span>
                                )}
                                {languageValue == "ro" && (
                                    <span className="article-title">Picioare Calde 2024</span>
                                )}
                                {languageValue == "se" && (
                                    <span className="article-title">Varma Fötter 2024</span>
                                )}
                            </h1>
                            <div className="article-info">
                                <div className="article-data">
                                    {languageValue == "en" && (
                                        <span>By Ligia Pintican</span>
                                    )}
                                    {languageValue == "ro" && (
                                        <span>De Ligia Pintican</span>
                                    )}
                                    {languageValue == "se" && (
                                        <span>Av Ligia Pintican</span>
                                    )}
                                </div>
                                {languageValue == "en" && (
                                    <div className="article-data">FEBRUARY 11, 2024</div>
                                )}
                                {languageValue == "ro" && (
                                    <div className="article-data">FEBRUARIE 11, 2024</div>
                                )}
                                {languageValue == "se" && (
                                    <div className="article-data">FEBRUARI 11, 2024</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <section className="section-container pd-t-40">
                    <div className="article-content-row article-width">
                        {languageValue == "en" && (
                            <div className="section-col1">
                                <div className="dialog-container-content">
                                    <p>
                                    Imagine a child's face lighting up as they receive a warm pair of boots, a hat, warm socks and a scarfs, a simple gift that can change their winter. This was the reality for thousands of children and families in Romania thanks to the Warm Feet 2024 project. 58 individuals from across Europe and the US joined forces with a dedicated Romanian team to bring warmth and the message of Jesus to 24 villages. They didn't just deliver supplies; they shared hugs, prayers, and genuine care. Warm Feet is more than just boots and socks; it's a story of love, faith, and the incredible impact of human connection. But don't just take our word for it – hear from those who experienced it firsthand:
                                    </p>

                                    <p>Joakim from Germany said: "Even the smallest effort is worth it. Seeing a boy's face light up after receiving a cap and scarf...amazing!  Helping a handicapped woman carry her gifts home, hand-in-hand with a little girl, was a powerful experience." </p>

                                    <p>Julia from Germany shared, "A little boy in Albesti stayed by my side for an hour after receiving his items, hugging me tightly. It was incredibly touching."  </p>
                                </div>

                                <div>
                                    <ImageSlider images={images} />
                                </div>
                                <div className="dialog-container-content">
                                    <p>
                                        These small moments of connection are the heart of Warm Feet.
                                    </p>

                                    <p>
                                        Reke from Hungary reflected on the deeper meaning of the project: "The difference between giving as a human and giving as a man of God is indescribable. It was amazing to see the work of Romanian Metro Ministry and the hearts of the people. Despite the challenges, I felt so much hope and love."
                                    </p>
                                </div>

                                <div>
                                    <ImageSlider images={images2} />
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        This sentiment was echoed by many, who felt blessed to be part of something so transformative.
                                    </p>

                                    <p>
                                        Krista and Geert, from Belgium, were struck by the poverty they witnessed, but also by the resilience of the people: "The blessing of the community leaders was a special moment… To have the children among us…serving…so overwhelming!"
                                    </p>
                                </div>

                                {/* <div >
                                    <img className="article-image-style" src={singlePoza} alt={"team"} />
                                </div> */}

                                <div className="dialog-container-content">
                                    <p>
                                        "Seeing people cheer for a pair of socks and a hat highlights the level of poverty in Romania," Teodor from the Metro Ministries Romania team observed.  "It makes you wonder how many other communities are in similar situations."
                                    </p>

                                    <p>
                                        Warm Feet 2024 wasn't just about meeting physical needs; it was about sharing the love of Jesus.  Countless children and adults responded to the Gospel message, finding hope and comfort in their Savior.
                                    </p>

                                    <div>
                                        <ImageSlider images={images3} />
                                    </div>

                                    <p>
                                        As Dana, a children's ministry leader in Maierus, shared, "Distributing the boots was a wonderful opportunity to share the Word of God with children and parents. The impact of this project in the community is significant."
                                    </p>
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        This project is a testament to the power of collaboration, with individuals and organizations from around the world coming together to make a difference. From the generous donors who funded the 2,900 pairs of boots to the volunteers who packed and transported supplies, every contribution played a vital role.
                                    </p>

                                    <p>
                                        The Warm Feet 2024 project is filled with stories like these – stories of hope, resilience, and the transformative power of love. It's a reminder that even the smallest act of kindness can have a profound impact.
                                    </p>

                                    <p>
                                        Want to be part of a story like this? Learn how you can get involved with Metro Ministries Romania and help us continue to spread warmth and hope.
                                    </p>
                                </div>

                                <iframe
                                    width="760"
                                    height="415"
                                    src={`https://www.youtube.com/embed/AN-3kVUDlbU`}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}

                        {languageValue == "se" && (
                            <div className="section-col1">
                                <div className="dialog-container-content">
                                    <p>
                                        Föreställ dig ett barns ansikte lysa upp när de får ett par varma stövlar – en enkel gåva som
                                        kan förändra hela deras vinter. Detta blev verklighet för tusentals barn och familjer i
                                        Rumänien tack vare projektet Varma Fötter 2024. Från hela Europa och USA gick 58
                                        personer samman med ett engagerat rumänskt team för att sprida värme och Jesu budskap
                                        till 24 byar. Men de levererade inte bara förnödenheter – de delade också ut kramar, böner
                                        och genuin omsorg.
                                    </p>

                                    <p>Varma Fötter handlar om mer än bara stövlar och strumpor; det är en berättelse om kärlek,
                                        tro och den otroliga kraften i mänsklig kontakt. Men låt dem som var där själva berätta –
                                        lyssna på deras upplevelser: </p>

                                    <p>Joakim från Tyskland berättade:
                                        "Även den minsta insatsen är värd det. Att se en pojkes ansikte lysa upp efter att ha fått en
                                        mössa och en halsduk… helt fantastiskt! Att hjälpa en funktionshindrad kvinna bära hem
                                        sina gåvor, hand i hand med en liten flicka, det var en stark upplevelse." </p>

                                    <p>Julia från Tyskland delade sin upplevelse:
                                        "En liten pojke i Albesti stannade vid min sida i en timme efter att han fått sina saker, och
                                        han höll mig hårt i handen hela tiden. Det var otroligt rörande." </p>
                                </div>

                                <div>
                                    <ImageSlider images={images} />
                                </div>
                                <div className="dialog-container-content">
                                    <p>
                                        Det är just dessa små ögonblick av mänsklig kontakt som är kärnan i Varma Fötter.
                                    </p>

                                    <p>
                                        Reke från Ungern reflekterade över projektets djupare innebörd:
                                        "Skillnaden mellan att ge som människa och att ge som en Guds man är obeskrivlig. Det var
                                        fantastiskt att se arbetet som Metro Ministries Rumänien gör och att få möta människornas
                                        hjärtan. Trots alla utmaningar kände jag så mycket hopp och kärlek."
                                    </p>
                                </div>

                                <div>
                                    <ImageSlider images={images2} />
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        Den känslan delades av många som kände sig välsignade att få vara en del av något så
                                        livsförvandlande.
                                    </p>

                                    <p>
                                        Krista och Geert från Belgien blev starkt berörda av den fattigdom de bevittnade, men också
                                        av människornas styrka och mod:
                                        "Att få välsignelse av samhällsledarna var ett speciellt ögonblick… Att få tjäna barnen mitt
                                        ibland oss… så överväldigande!"
                                    </p>
                                </div>

                                {/* <div >
                                    <img className="article-image-style" src={singlePoza} alt={"team"} />
                                </div> */}

                                <div className="dialog-container-content">
                                    <p>
                                        Teodor från Metro Ministries Rumänien reflekterade över vad han sett:
                                        "Att se människor jubla över ett par strumpor och en mössa visar verkligen hur svår
                                        fattigdomen är i Rumänien. Det får en att undra hur många andra samhällen som lever i
                                        liknande situationer."
                                    </p>

                                    <p>
                                        Varma Fötter 2024 handlade inte bara om att möta fysiska behov – det handlade om att dela
                                        Jesu kärlek. Otaliga barn och vuxna tog emot evangeliets budskap och fann hopp och tröst i
                                        sin Frälsare.
                                    </p>

                                    <div>
                                        <ImageSlider images={images3} />
                                    </div>

                                    <p>
                                        Som Dana, en ledare inom barnverksamheten i Maierus, uttryckte det:
                                        "Att dela ut stövlarna var en fantastisk möjlighet att sprida Guds ord till både barn och
                                        föräldrar. Projektets påverkan på samhället är betydande."
                                    </p>
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        Detta projekt är ett bevis på kraften i samarbete – individer och organisationer från hela
                                        världen gick samman för att göra skillnad. Från de generösa givare som finansierade de 2
                                        900 stövlarna till volontärerna som packade och transporterade förnödenheterna – varje
                                        insats var avgörande.
                                    </p>

                                    <p>
                                        Varma Fötter 2024 är fyllt av berättelser som dessa – berättelser om hopp, uthållighet och
                                        kärlekens förvandlande kraft. Det är en påminnelse om att även den minsta vänliga handling
                                        kan ha en djupgående inverkan.
                                    </p>

                                    <p>
                                        Vill du vara en del av en sådan berättelse? Ta reda på hur du kan engagera dig i Metro
                                        Ministries Rumänien och hjälpa oss att fortsätta sprida värme och hopp.
                                    </p>
                                </div>
                            </div>
                        )}

                        {languageValue == "ro" && (
                            <div className="section-col1">
                                <div className="dialog-container-content">
                                    <p>
                                        Imaginează-ți chipul unui copil luminându-se când primește o pereche de cizme călduroase, un dar simplu care îi poate schimba iarna. Aceasta a fost realitatea pentru mii de copii și familii din România, datorită proiectului "Picioare Calde 2024". 58 de persoane din întreaga Europă și SUA și-au unit forțele cu o echipă românească dedicată pentru a aduce căldură și mesajul lui Isus în 24 de sate. Nu au livrat doar provizii; au împărtășit îmbrățișări, rugăciuni și o grijă sinceră. "Picioare Calde" este mai mult decât cizme și șosete; este o poveste de dragoste, credință și impactul incredibil al conexiunii umane. Dar nu ne credeți doar pe cuvânt – ascultați-i pe cei care au trăit-o direct:
                                    </p>

                                    <p>Joakim din Germania a spus: "Chiar și cel mai mic efort merită. Să vezi chipul unui băiat luminându-se după ce a primit o căciulă și o eșarfă... uimitor! Să ajuți o femeie cu handicap să-și care darurile acasă, ținând-o de mână cu o fetiță, a fost o experiență puternică." </p>

                                    <p>Julia din Germania a împărtășit: "Un băiețel din Albești a rămas lângă mine o oră după ce a primit lucrurile lui, îmbrățișându-mă strâns. A fost incredibil de emoționant." </p>
                                </div>

                                <div>
                                    <ImageSlider images={images} />
                                </div>
                                <div className="dialog-container-content">
                                    <p>
                                        Aceste mici momente de conexiune sunt inima proiectului "Picioare Calde".
                                    </p>

                                    <p>
                                        Reke din Ungaria a reflectat asupra sensului mai profund al proiectului: "Diferența dintre a dărui ca om și a dărui ca om al lui Dumnezeu este indescriptibilă. A fost uimitor să văd lucrarea Metro Ministries România și inimile oamenilor. În ciuda provocărilor, am simțit atâta speranță și dragoste."
                                    </p>
                                </div>

                                <div>
                                    <ImageSlider images={images2} />
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        Acest sentiment a fost împărtășit de mulți, care s-au simțit binecuvântați să facă parte din ceva atât de transformator.
                                    </p>

                                    <p>
                                        Krista și Geert, din Belgia, au fost loviți de sărăcia pe care au văzut-o, dar și de reziliența oamenilor: "Binecuvântarea liderilor comunității a fost un moment special... Să-i avem pe copii printre noi... slujind... atât de copleșitor!"
                                    </p>
                                </div>

                                {/* <div >
                                    <img className="article-image-style" src={singlePoza} alt={"team"} />
                                </div> */}

                                <div className="dialog-container-content">
                                    <p>
                                        "Să vezi oameni aclamând pentru o pereche de șosete și o căciulă evidențiază nivelul de sărăcie din România", a observat Teodor din echipa Metro Ministries România. "Te face să te întrebi câte alte comunități sunt în situații similare."
                                    </p>

                                    <p>
                                        "Picioare Calde 2024" nu a fost doar despre satisfacerea nevoilor fizice; a fost despre împărtășirea dragostei lui Isus. Nenumărați copii și adulți au răspuns la mesajul Evangheliei, găsind speranță și consolare în Mântuitorul lor.
                                    </p>

                                    <div>
                                        <ImageSlider images={images3} />
                                    </div>

                                    <p>
                                        Așa cum a împărtășit Dana, lider al lucrării cu copiii din Măieruș, "Distribuirea cizmelor a fost o ocazie minunată de a împărtăși Cuvântul lui Dumnezeu cu copiii și părinții. Impactul acestui proiect în comunitate este semnificativ."
                                    </p>
                                </div>

                                <div className="dialog-container-content">
                                    <p>
                                        Acest proiect este o mărturie a puterii colaborării, cu persoane și organizații din întreaga lume care s-au unit pentru a face o diferență. De la donatorii generoși care au finanțat cele 2.900 de perechi de cizme până la voluntarii care au împachetat și transportat proviziile, fiecare contribuție a jucat un rol vital.
                                    </p>

                                    <p>
                                        Proiectul "Picioare Calde 2024" este plin de povești ca acestea – povești de speranță, reziliență și puterea transformatoare a dragostei. Este o amintire că până și cel mai mic act de bunătate poate avea un impact profund.
                                    </p>

                                    <p>
                                        Vrei să faci parte dintr-o astfel de poveste? Află cum te poți implica în Metro Ministries România și ajută-ne să continuăm să răspândim căldură și speranță.
                                    </p>
                                </div>
                            </div>
                        )}
                        <div className="section-col2">
                            <div className="sidebar_widget has_border mb-40">
                                <div className="sidebar_title">
                                    <h4 className="sidebar_title_text has_border"><span></span>Latest News</h4>
                                </div>

                                <div className="feed_widget-content">
                                    <div className="single_feed_widget has_border">
                                        <div className="feed_widget_img">
                                            <a href="/blog-details">
                                                <img src={rondola5} alt="img" />
                                            </a>
                                        </div>
                                        <div className="feed_widget_text">
                                            <h5 className="feed_widget_title theme-1">
                                                <a href="/">Spread your wings and fly</a>
                                            </h5>
                                            <a className="feed_widget_date theme-1" href="/blog-details#">
                                                <i className="fal fa-calendar-alt"></i>24th August 2024
                                            </a>
                                        </div>
                                    </div>
                                    <div className="single_feed_widget has_border">
                                        <div className="feed_widget_img">
                                            <a href="/blog-details">
                                                <img src={rondola4} alt="img" />
                                            </a>
                                        </div>
                                        <div className="feed_widget_text">
                                            <h5 className="feed_widget_title theme-1">
                                                <a href="/">Spring update 2024</a>
                                            </h5>
                                            <a className="feed_widget_date theme-1" href="/blog-details#">
                                                <i className="fal fa-calendar-alt"></i>24th May 2024
                                            </a>
                                        </div>
                                    </div>
                                    {/* <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div> */}
                                    <div className="single_feed_widget">
                                        <div className="feed_widget_img">
                                            <a href="/blog-details">
                                                <img src={rondola} alt="img" />
                                            </a>
                                        </div>
                                        <div className="feed_widget_text">
                                            <h5 className="feed_widget_title theme-1">
                                                <a href="/">Warm Feet 2023</a>
                                            </h5>
                                            <a className="feed_widget_date theme-1" href="/blog-details#">
                                                <i className="fal fa-calendar-alt"></i>20 January 2024
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
};

const enhance = compose(withTranslation("translation"));

export default enhance(NewsPage5);