import React, { useState,useEffect } from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

import "../styles/vision.css";
import Reveal from "../../utils/motion/reveal";

import { motion } from "framer-motion";

import {
  banner2,
  rhemaHouse1,
  albestiHouse,
  banner3,
  banner4,
} from "../../assets";

const Vision = (props) => {
  const { t } = props;

  const [showFirstTab, setShowFirstTab] = useState(true);
  const [showSecondTab, setShowSecondTab] = useState(false);
  const [showThirdTab, setShowThirdTab] = useState(false);
  const [imageHeigth, setImageHeight] = useState("700px")

  const handleClickShow2 = () => {
    setShowFirstTab(false);
    setShowSecondTab(true);
    setShowThirdTab(false);
  };

  const handleClickShow1 = () => {
    setShowFirstTab(true);
    setShowSecondTab(false);
    setShowThirdTab(false);
  };

  const handleClickShow3 = () => {
    setShowFirstTab(false);
    setShowSecondTab(false);
    setShowThirdTab(true);
  }

  const updateImageHeight = () => {
    if (window.innerWidth <= 480) {
      setImageHeight("370px"); // For phones
    } else if (window.innerWidth <= 768) {
      setImageHeight("540px"); // For tablets
    } else {
      setImageHeight("700px"); // Default for larger screens
    }
  };

  useEffect(() => {
    // Set the initial height
    updateImageHeight();

    // Update height on screen resize
    window.addEventListener("resize", updateImageHeight);

    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateImageHeight);
  }, []);

  const tabVariants = {
    hidden: { height: '0px',padding: '0', overflow:'hidden' }, // Collapsed state
    visible: { height: imageHeigth, padding: '10% 8% 11% 11%' }, // Expanded state
  };

  return (
    <>
      <Reveal>
      <section>
        <div className="vision-section-style">
          <div>
            <div className="heading-tabs">
              <h4
                onClick={handleClickShow1}
                className="first-color-tab tab-style inside-tab"
              >
                {t("Menu.Mission")}
              </h4>
              <h4
                onClick={handleClickShow2}
                className="second-color-tab tab-style inside-tab"
              >
                {t("Menu.Vision")}
                
              </h4>
              <h4 
                onClick={handleClickShow3} 
                className="third-color-tab tab-style inside-tab">
                {t("Menu.Values")}
              </h4>
            </div>

            <div className="tab-content">
              <motion.div
                className="first-tab-content-color tab-content-style"
                initial="hidden"
                animate={showFirstTab ? "visible" : "hidden"}
                variants={tabVariants}
                transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
              >
                {
                  showFirstTab && (
                    <div className="tab-content-text">
                  <h2>
                    {t("Vision.MissionStatement")}
                  </h2>
                  <p>{t("Vision.Parag1")}</p>

                  <p>{t("Vision.Parag2")}</p>
                </div>
                  )
                }
              </motion.div>

              <motion.div
                className="second-tab-content-color tab-content-style"
                initial="hidden"
                animate={showSecondTab ? "visible" : "hidden"}
                variants={tabVariants}
                transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
              >
                <div className="tab-content-text">
                  <h2 >
                    {t("Vision.RhemaHouseVision")}
                  </h2>
                  <p>{t("Vision.Parag3")}</p>
                  <p>{t("Vision.Parag4")}</p>
                  <p>{t("Vision.Parag5")}</p>
                  <p>{t("Vision.Parag6")}</p>
                </div>
              </motion.div>

              <motion.div
                className="third-tab-content-color tab-content-style"
                initial="hidden"
                animate={showThirdTab ? "visible" : "hidden"}
                variants={tabVariants}
                transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
              >
                <div className="tab-content-text">
                  <h2 >
                    Our values 
                  </h2>
                  <p><strong>R-H-E-M-A</strong></p>
                  <p><strong>R</strong>elationships</p>
                  <p><strong>H</strong>olistic</p>
                  <p><strong>E</strong>xcellency</p>
                  <p><strong>M</strong>entorship</p>
                  <p><strong>A</strong>uthentic</p>
                  
                </div>
              </motion.div>
            </div>
          </div>
          <div className="tab-image">

            <motion.div
              className="vision-container-image"
              style={{ backgroundImage: `url(${banner2})` }}
              initial="hidden"
              animate={showFirstTab ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
            >

            </motion.div>

            <motion.div
              className="vision-container-image"
              style={{ backgroundImage: `url(${banner4})` }}
              initial="hidden"
              animate={showSecondTab ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
            >
              
            </motion.div>

            <motion.div
              className="vision-container-image"
              style={{ backgroundImage: `url(${banner3})` }}
              initial="hidden"
              animate={showThirdTab ? "visible" : "hidden"}
              variants={tabVariants}
              transition={{ duration: 0.5, ease: "easeInOut" }} // animation duration and easing
            >
              
            </motion.div>
          </div>
        </div>
      </section>
      </Reveal>
    </>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Vision);
