import React from "react";
import { rondola, rondola4, rondola5 } from "../../../assets";
import ImageSlider from "../../../utils/image-slider/image-slider";
import Footer from "../../Footer/Footer";
import {
    camp1,
    camp10,
    camp11,
    camp12,
    camp13,
    camp14,
    camp15,
    camp16,
    camp2,
    camp3,
    camp4,
    camp5,
    camp6,
    camp7,
    camp8,
    camp9,
  } from "../../../assets/news-images/summer camp 2024";

const NewsPage3 = () => {
  const images = [camp1, camp2, camp3, camp4, camp5];
  const images2 = [camp6, camp7, camp8, camp9, camp10];
  const images3 = [camp11, camp12, camp13, camp14, camp15];

  return (
    <div>
      <div className="page-container">
        <div
          className="page-default-banner"
          style={{
            backgroundImage: `url(${rondola5})`,
            backgroundSize: "cover",
            backgroundPosition: "50%",
            padding: "145px 0",
          }}
        >
          <div className="article">
            <div className="row-article">
              <h1>
                <span class="article-title">
                  Spread your wings and fly! 9th - 12th July 2024
                </span>
              </h1>
              <div className="article-info">
                <div className="article-data">
                  <span>By Ligia Pintican</span>
                </div>
                <div className="article-data">24th August 2024</div>
              </div>
            </div>
          </div>
        </div>

        <section className="section-container pd-t-40">
          <div className="article-content-row article-width">
            <div className="section-col1">
              <div className="dialog-container-content">
                <h2 style={{ textAlign: "center", paddingTop: "2rem" }}>
                  Goldberich and Hoghilag children and teens
                </h2>

                <p>
                  When we look at birds, we might imagine that it is so easy to
                  fly; they seem to be so free to go places at times. We humans
                  are bound to the earth by the law of gravity, but our soul and
                  mind...are they also bounded?
                </p>

                <p>"Spread your wings and fly"</p>

                <div>
                  <ImageSlider images={images} />
                </div>

                <p>
                  This was the topic of our camp with children and teenagers
                  from Sighisoara/Goldberich and Hoghilag.
                </p>

                <p>
                  In the quiet village of Garbova, able to cool off in the hot
                  afternoons, in the small camp pool, we discovered the way to
                  fly.
                </p>

                <div>
                  <ImageSlider images={images2} />
                </div>

                <p>
                  "If anyone is in Me (says Jesus), he is a new creature, old
                  things have passed away, behold, all things have become new."
                  2 Corinthians 5:17
                </p>

                <div>
                  <ImageSlider images={images3} />
                </div>

                <p>
                  Watching and learning from the "stories made alive" of: the
                  tax collector Mathew, Mary Magdalene or Saul, our teenagers
                  did a great job showing how Jesus touched and changed lives.
                </p>

                <div className="image-position">
                  <img
                    className="article-image-style"
                    
                    src={camp16}
                  />
                </div>

                <p>How close is your soul to flying? Take the next step!</p>
              </div>
            </div>

            <div className="section-col2">
              <div className="sidebar_widget has_border mb-40">
                <div className="sidebar_title">
                  <h4 class="sidebar_title_text has_border">
                    <span></span>Latest News
                  </h4>
                </div>

                <div className="feed_widget-content">
                  <div class="single_feed_widget has_border">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola5} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Spread your wings and fly</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>24th August 2024
                      </a>
                    </div>
                  </div>
                  <div class="single_feed_widget has_border">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola4} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Spring update 2024</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>24th May 2024
                      </a>
                    </div>
                  </div>
                  {/* <div class="single_feed_widget has_border">
                            <div class="feed_widget_img">
                                <a href="/blog-details">
                                    <img src={rondola5} alt="img" />
                                </a>
                            </div>
                            <div class="feed_widget_text">
                                <h5 class="feed_widget_title theme-1">
                                    <a href="/">Spread your wings and fly</a>
                                </h5>
                                <a class="feed_widget_date theme-1" href="/blog-details#">
                                <i class="fal fa-calendar-alt"></i>24th August 2024
                                </a>
                            </div>
                        </div> */}
                  <div class="single_feed_widget">
                    <div class="feed_widget_img">
                      <a href="/blog-details">
                        <img src={rondola} alt="img" />
                      </a>
                    </div>
                    <div class="feed_widget_text">
                      <h5 class="feed_widget_title theme-1">
                        <a href="/">Warm Feet 2023</a>
                      </h5>
                      <a class="feed_widget_date theme-1" href="/blog-details#">
                        <i class="fal fa-calendar-alt"></i>20 January 2024
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default NewsPage3;
