import React from "react";

import { compose } from "redux";
import { withTranslation } from "react-i18next";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { white } from "@mui/material/colors";

import "../styles/footer.css";
import Reveal from "../../utils/motion/reveal";

import { backgroundImage } from '../../assets';
import { Link } from "@mui/material";

const Footer = (props) => {
  const { t } = props;

  return (
    <Reveal>
      <div className="footer-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="footer-row">
          <div className="col">
            <div className="col-links">
              <h2 className="title">{t("Menu.Projects")}</h2>
              <a href="#" className="contact-hover-underline-animation">
                Sighişoara
              </a>
              <a href="#" className="contact-hover-underline-animation">
                Albeşti
              </a>
              <a href="#" className="contact-hover-underline-animation">
                {t("Menu.HouseOfJoy")}
              </a>
              <a href="#" className="contact-hover-underline-animation">
                {t("Menu.DaycareCenter")}
              </a>
              <a href="#" className="contact-hover-underline-animation">
                Hoghilag
              </a>
            </div>
          </div>

          <div className="col">
            <div className="col-title">
              <h2 className="title">Contact</h2>
            </div>
            <div className="col-info">
              <div className="info-style">
                <p>
                  <strong>{t("Footer.PublicRelationship")}</strong>: Ligia Pintican
                  Englof
                </p>
                <p>
                  <strong>{t("Footer.Tel")}</strong> +46-763 246 001
                </p>
                <p>
                  <strong>{t("Footer.Email")}:</strong> lgpenglof@gmail.com
                </p>
              </div>
              <div className="info-style">
                <p>
                  <strong>{t("Footer.Office")}</strong>
                </p>
                <p>
                  <strong>{t("Footer.Tel")}</strong> 0040 774 533 859; <strong>Social:</strong> 0040 783 262 555
                </p>
              </div>
              <div className="info-style">
                <p>
                  <strong>{t("Footer.ProjectCoordinator")}</strong>: Dionis Bodiu
                </p>
                <p>
                  <strong>{t("Footer.Tel")}:</strong> +40721-215425
                </p>
                <p>
                  <strong>{t("Footer.Email")}:</strong> dyobodiu@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="col-title">
              <h2 className="title">{t("Footer.FollowUs")}</h2>
            </div>
            <div className="col-follow">
              <Link
                href="https://www.facebook.com/MetroRomania"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "white", textDecoration: "none" }}
              >
                <FacebookIcon sx={{ color: "white", fontSize: 40 }} />
              </Link>
              <TwitterIcon sx={{ color: "white" }} />
              <InstagramIcon sx={{ color: "white" }} />
              <LinkedInIcon sx={{ color: "white" }} />
            </div>
          </div>

          <div className="col"></div>
        </div>
      </div>
    </Reveal>
  );
};

const enhance = compose(withTranslation("translation"));

export default enhance(Footer);
